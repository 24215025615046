import { Bar, BarChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const localised = Intl.NumberFormat("en-GB");

export default function VerticalBarChart({ 
  data, 
  xAxis, 
  yAxis, 
  xLabel,
  yLabel,
}) {

  const sizes = {
    chartContainer: 625,
    chart: 650,
    chartXAxisFontSize: 12,
    wrapperStyle: {
      paddingTop: "25px",
    }
  }

  return (
    <ResponsiveContainer height={sizes.chart}>
      <BarChart data={data} barSize={35} layout="vertical" margin={{bottom: 40, left: 40}}>
        <XAxis
          type="number"
          allowDecimals={false}
          tick={{ fontSize: 10 }}
          tickFormatter={(item) => localised.format(item)}
          domain={[0, 'dataMax + 100']}
        >
          <Label value={xLabel} position="bottom" />
        </XAxis>
        <YAxis
          type="category"
          interval={0}
          tick={{ fontSize: sizes.chartXAxisFontSize }}
          dataKey={yAxis}
        >
          <Label value={yLabel} angle={-90} position="left" offset={10} style={{textAnchor: "middle"}} />
        </YAxis>
        <CartesianGrid />
        <Bar dataKey={xAxis} fill="var(--secondary)" />
        <Tooltip
          cursor={false}
          formatter={(value, name, props) => {
            const displayName = xLabel || name;
            return [localised.format(value), displayName];
          }}
        />
      </BarChart>
    </ResponsiveContainer>  )
}
