import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const localised = Intl.NumberFormat("en-GB");

export default function LineGraph({ 
  data, 
  xAxis, 
  yAxis,
  yMin = 'auto', 
  yMax = 'auto',
  xLabel,
  yLabel,
}) {

  const sizes = {
    chartContainer: 625,
    chart: 650,
    chartXAxisFontSize: 12,
    wrapperStyle: {
      paddingTop: "25px",
    }
  }

  return (
    <ResponsiveContainer height={sizes.chart}>
      <LineChart data={data} barSize={50} layout="horizontal" margin={{bottom: 40, left: 40}}>
        <XAxis
          type="category"
          interval={0}
          tick={{ fontSize: sizes.chartXAxisFontSize }}
          dataKey={xAxis}
        >
          <Label value={xLabel} position="bottom" />
        </XAxis>
        <YAxis
          type="number"
          allowDecimals={false}
          tick={{ fontSize: 10 }}
          tickFormatter={(item) => localised.format(item)}
          domain={[yMin, yMax]}
        >
          <Label value={yLabel} angle={-90} position="left" style={{textAnchor: "middle"}} />
        </YAxis>
        <CartesianGrid />
        <Line dataKey={yAxis} fill="var(--secondary)" />
        <Tooltip
          cursor={false}
          formatter={(value, name, props) => {
            const displayName = yLabel || name;
            return [localised.format(value), displayName];
          }}
        />
      </LineChart>
    </ResponsiveContainer>  )
}
