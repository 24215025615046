import { CartesianGrid, Cell, Label, Legend, Pie, PieChart as RechartsPieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Async, { useAsync } from "react-select/async";
import { useState, useEffect, useCallback } from "react";
import request from "../helpers/request";
import endpoints from "../helpers/endpoints";

const localised = Intl.NumberFormat("en-GB");

export default function PieChart({ 
  data, 
  dataKey,
  nameKey,
  displayNames,
  searchable = false,
  searchFunction = ()=>{},
  setSearch = {},
  tableCategory = "Category",
}) {

  const sizes = {
    chartContainer: 625,
    chart: searchable ? 611 : 650,
    chartXAxisFontSize: 12,
    wrapperStyle: {
      paddingTop: "25px",
    }
  }

  const COLORS = [
    "var(--ltap-blue)",
    "var(--ltap-green)",
    "var(--ltap-yellow)",
    "var(--ltap-pink)",
    "var(--ltap-orange)",
    "var(--ltap-grey)",
  ]

  const totalValue = data.reduce((acc, curr) => acc + curr.value, 0)

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      {searchable &&
        <Async 
          placeholder="Search..."
          loadOptions={searchFunction}
          defaultOptions
          onChange={setSearch}
        />
      }
      <ResponsiveContainer height={sizes.chart}>
        <RechartsPieChart margin={{top: searchable ? 0 : 39}}>
          <Pie data={data} dataKey={dataKey} nameKey={nameKey} fill="var(--primary)" label={renderCustomizedLabel} labelLine={false} isAnimationActive={false}>
            {data?.sort((a, b) => b.value - a.value).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip 
            cursor={false} 
            formatter={(value, name, props) => {
              const displayName = displayNames[name] || name
              return [localised.format(value), displayName]
            }}
          />
        </RechartsPieChart>
      </ResponsiveContainer>  
      {data.length && 
        <table className="table borders squish smaller-text left">
          <thead>
            <tr>
              <th>Color</th>
              <th>{tableCategory}</th>
              <th style={{textAlign: "right"}}>Count</th>
              <th style={{textAlign: "right"}}>Percentage</th>
            </tr>
          </thead>
          <tbody>
            {data.sort((a, b) => b.value - a.value).map((d, index) => {
              const color = COLORS[index % COLORS.length];
              return (
                <tr>
                  <td>            
                    <span
                      style={{
                        display: "inline-block",
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        backgroundColor: color,
                      }}
                    />
                  </td>
                  <td>{displayNames[d.name]}</td>
                  <td style={{textAlign: "right"}}>{localised.format(d.value)}</td>
                  <td style={{textAlign: "right"}}>{((d.value / totalValue) * 100).toFixed(0)}%</td>
                </tr>
              )})}
          </tbody>
        </table>
      }
    </>
  )
}
