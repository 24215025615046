import { useCallback, useEffect, useState } from "react";
import request from "../helpers/request";
import Pagination from "../components/pagination/pagination";
import "./smartTable.scss"

const localised = Intl.NumberFormat("en-GB");

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const columnHelper = createColumnHelper()


function SortingIndicator({direction, visible}) {
  return (
    <i style={{ opacity: visible ? '100%' : '0', paddingLeft: 5, verticalAlign: 'middle' }} className={"fa-solid fa-sort-" + (direction === "up" ? "up" : "down")}></i>       
  )
}

function SortableHeader({ column, table, title, align = "right" }) {
  const sorting = table.getState().sorting;
  const isSorted = sorting.find(sort => sort?.id === column.id) || false;

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <SortingIndicator direction={isSorted?.desc ? "down" : "up"} visible={!!isSorted} />
      <div style={{ textAlign: align }}>
        {title}
      </div>
    </div>
  );
}

function getFormattedDate() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');  // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}_${month}_${day}__${hours}_${minutes}_${seconds}`;
}

// This table is smart because it can sort, search, paginate, and even generate CSV
export default function SmartTable({ 
  columns, 
  dataEndpoint, 
  exportEndpoint = null, 
  filenamePrefix, 
  defaultSort, 
  searchable = true
}) {
  const [data, setData] = useState([])
  const [page, setPage] = useState({
    page: 1,
    last_page: null
  });
  const [sorting, setSorting] = useState([
    defaultSort
  ])
  const [searchQuery, setSearchQuery] = useState('')
  const [downloading, setDownloading] = useState(false)
  const [tableColumns, setTableColumns] = useState([])

  const parseColumns = useCallback(() => {
    const _columns = columns.map(c => columnHelper.accessor(c.accessor, {

      id: c.id ?? c.accessor,

      header: c.sortable
        ? ({ column, table }) => <SortableHeader column={column} table={table} title={c.headerDisplay} />
        : <div style={{textAlign: c.headerAlign}}>{c.headerDisplay}</div>,

      cell: ({ getValue }) => {
        const value = getValue();
        const numericValue = Number(value)
        const formattedValue = Number.isNaN(numericValue)
          ? value
          : localised.format(numericValue); // Use the raw value if it's not a number

        return (
          <div style={{ textAlign: c.cellAlign ?? 'left' }}>
            {formattedValue}
          </div>
        );
      },

      enableSorting: c.sortable ?? true,

      meta: {
        colSpan: c.columnWidth ?? 1,
      },
    }))

    setTableColumns(_columns)
  }, [columns])

  useEffect(() => {
    parseColumns();
  }, [parseColumns])

  const table = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting,
    },
    onSortingChange: (newSorting) => {
      const currentSorting = newSorting(); // Call the function to get the current sorting state
      const clickedColumn = currentSorting[0]?.id;

      let updatedSorting;
      if (!sorting.length || sorting[0].id !== clickedColumn) {
        // No sorting or different column: start with ascending
        updatedSorting = [{ id: clickedColumn, desc: false }];
      } else if (sorting[0].desc === false) {
        // Currently ascending: switch to descending
        updatedSorting = [{ id: clickedColumn, desc: true }];
      } else {
        // Currently descending: remove sorting
        updatedSorting = [];
      }

      setSorting(updatedSorting);
    },
  })

  let timeout
  const loadPage = useCallback((page = 1) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      request(true).get(dataEndpoint, {
        params: {
          page,
          sorting,
          search: searchQuery,
        }
      })
        .then(e => {
          setData(e.data.data.data)
          setPage({
            page: e.data.data.current_page,
            last_page: e.data.data.last_page
          })
        })
    }, 300)
  }, [sorting, searchQuery])

  useEffect(() => {
    if (defaultSort) {
      loadPage()
    }
  }, [loadPage])

  const exportToExcel = useCallback(() => {
    setDownloading(true)
    request(true).get(exportEndpoint, {
      params: {
        page,
        sorting,
        search: searchQuery,
      }
    }).then(response => {
        const timestamp = getFormattedDate();
        const filename = `${filenamePrefix}-${timestamp}.csv`
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloading(false)
      }).catch(e => {
        window.alert("Failed to generate CSV file. Please contact administrator if this keeps occurring.")
        setDownloading(false)
      })
  }, [sorting, searchQuery])

  if (!tableColumns) {
    return <div>Loading</div>
  }

  return (
    <div className="smart-table">
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', justifyContent: "space-between", maxWidth: "100%" }}>
          {searchable &&
            <input type="text" placeholder="Search..." size={25} className="input" onChange={e => setSearchQuery(e.target.value)} value={searchQuery} />
          }
          {exportEndpoint && 
            <div style={{ marginInline: 10 }}>
              {downloading ? 
                <i style={{ color: "var(--primary)"}} className={"fa-solid fa-check"}></i>       
                :
                <img src='/Excel-logo.svg' style={{cursor: 'pointer', height: '24px', width: '24px' }} onClick={ () => exportToExcel()}></img>
              }
            </div>
          }
        </div>
        <div style={{ display: 'grid', gap: 10, paddingBottom: "15px" }}>
          <table className='table borders squish smaller-text left' style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} style={{ borderCollapse: "collapse" }}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} onClick={header.column.getToggleSortingHandler()} style={{ borderInline: "1px solid white", paddingInline: 10 }} colSpan={header.column.columnDef.meta?.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} style={{paddingInline: 10}} colSpan={cell.column.columnDef.meta?.colSpan}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination page={ page.page } pages={ page.last_page } pageChange={ loadPage } style={{margin: 'auto'}}/>
    </div>
  )
}
