import { view } from "@risingstack/react-easy-state";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer"
import { useCallback, useEffect, useState } from "react";
import request from "../../../helpers/request";
import endpoints from "../../../helpers/endpoints";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "./VolumeOfLandlords.scss"
import VerticalBarChart from "../../../GenericPageElements/VerticalBarChart";
import LineGraph from "../../../GenericPageElements/LineGraph";
import { Tabs } from "@mui/base/Tabs";
import { Tab } from "@mui/base/Tab";
import { TabsList } from '@mui/base/TabsList';

export default view(withRouter(function VolumeOfLandlords() {
  const [data, setData] = useState([])
  const [timePeriod, setTimePeriod] = useState("monthly")

  const monthlyColumns = {
    category: { field: 'year-month', name: 'Month' },
    number: { field: 'number_of_landlords_added', name: 'Number of Landlords Added' },
    cumulative: { field: 'cumulative_total', name: 'Cumulative Total' }
  };

  const yearlyColumns = {
    category: { field: 'year_registered', name: 'Year' },
    number: { field: 'counts', name: 'Number of Landlords Added' },
    cumulative: { field: 'cumulative_total', name: 'Cumulative Total' }
  };

  const [columns, setColumns] = useState(monthlyColumns)

  const getData = useCallback(() => {
    request(true).get(endpoints.VOLUME_OF_LANDLORDS_SUMMARY, {
      params: {
        timePeriod: timePeriod,
      }
    })
      .then(e => {
        // Convert string number values to actual numbers
        const parsedData = e.data.data.map((item) =>
          Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
              key,
              /^\d+$/.test(value) ? parseInt(value, 10) : value,
            ])
          )
        );
        setData(parsedData)
        setColumns(timePeriod === "yearly" ? yearlyColumns : monthlyColumns)
      })
  }, [timePeriod])

  useEffect(() => {
    getData()
  }, [getData])

  const handleChange = (event, newValue) => {
    setTimePeriod(newValue)
  }

  return (
    <GenericPageContainer
      containerWidth="92vw"
      title="Volume of Landlords"
      titleIconClass={"fak fa-id-house-icon fa-2x colour-primary"}
    >
      <div className="vol">
        <div>
          <Tabs value={timePeriod} onChange={handleChange}>
            <TabsList style={{display: 'flex', justifyContent: 'center', gap: 15}}>
              <Tab value={'monthly'} className="button data-tab">Monthly</Tab>
              <Tab value={'yearly'} className="button data-tab">Yearly</Tab>
            </TabsList>
          </Tabs>
        </div>
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5vw"}}>
          <div>
            <h4 style={{textAlign: "center"}}>{timePeriod === "yearly" ? "Number of Landlords Added per Year" : "Number of Landlords Added per Month"}</h4>
            <VerticalBarChart 
              data={data} 
              xAxis={columns.number.field}
              yAxis={columns.category.field}
              xLabel={columns.number.name}
              yLabel={columns.category.name}
            />
          </div>
          <div>
            <h4 style={{textAlign: "center"}}>Number of Landlords Added Over Time</h4>
            <LineGraph 
              data={data} 
              xAxis={columns.category.field}
              yAxis={columns.cumulative.field}
              xLabel={columns.category.name}
              yLabel={columns.cumulative.name}
            />
          </div>
        </div>
      </div>
    </GenericPageContainer>
  )
}))
