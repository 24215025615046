import { view } from "@risingstack/react-easy-state";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer"
import { useCallback, useEffect, useState } from "react";
import request from "../../../helpers/request";
import endpoints from "../../../helpers/endpoints";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "./ResponsibilityForCharges.scss"
import PieChart from "../../../GenericPageElements/PieChart";
import SmartTable from "../../../GenericPageElements/SmartTable";

export default view(withRouter(function VolumeOfLandlords() {
  const [data, setData] = useState([])
  const [company, setCompany] = useState({})
  const [companyData, setCompanyData] = useState([])

  const displayNames = {
    'total_tenant_responsibility': 'Total Tenant Responsibility',
    'total_owner_landlord_responsibility': 'Total Owner/Landlord Responsibility',
    'total_agent_responsibility': 'Total Agent Responsibility',
    'total_unassigned': 'Total Unassigned',
    'tenant_responsibility': 'Tenant Responsibility',
    'owner\/landlord_responsibility': 'Owner/Landlord Responsibility',
    'agent_responsibility': 'Agent Responsibility',
    'unassigned': 'Unassigned',
  }

  const getTotals = useCallback(() => {
    request(true).get(endpoints.RESPONSIBILITY_FOR_CHARGES_TOTALS_PIECHART)
      .then(e => {
        // Convert string number values to actual numbers
        const parsedData = e.data.piechart.map((item) =>
          Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
              key,
              /^\d+$/.test(value) ? parseInt(value, 10) : value,
            ])
          )
        );
        setData(parsedData)
      })
  }, [])

  useEffect(() => {
    getTotals()
  }, [getTotals])

  const getCompany = useCallback(() => {
    request(true).get(endpoints.RESPONSIBILITY_FOR_CHARGES_COMPANY_PIECHART, {
      params: {
        ltap_company: company?.value
      }
    })
      .then(e => {
        if (!e.data || !e.data.piechart) return setCompanyData([])

        // Convert string number values to actual numbers
        const parsedData = e.data.piechart.map((item) =>
          Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
              key,
              /^\d+$/.test(value) ? parseInt(value, 10) : value,
            ])
          )
        );
        setCompanyData(parsedData)
      })
  }, [company])

  useEffect(() => {
    getCompany()
  }, [getCompany])

  const getSearch = useCallback(async (inputValue) => {
    return request(true).get(endpoints.RESPONSIBILITY_FOR_CHARGES_COMPANY_LIST, {
      params: {
        search: inputValue
      }
    }).then(e => e.data.data)
  })

  return (
    <GenericPageContainer
      containerWidth="92vw"
      title="Responsibility For Charges"
      titleIconClass={"fak fa-id-house-icon fa-2x colour-primary"}
    >
      <div className="rfc">
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5vw"}}>
          <div>
            <h4 style={{textAlign: "center"}}>Responsibility for Charges by Company</h4>
            <PieChart
              data={companyData}
              dataKey="value"
              nameKey="name"
              displayNames={displayNames}
              searchable={true}
              searchFunction={getSearch}
              setSearch={setCompany}
              tableCategory="Responsibility"
            />
          </div>
          <div>
            <h4 style={{textAlign: "center"}}>Responsibility for Charges Total</h4>
            <PieChart
              data={data}
              dataKey="value"
              nameKey="name"
              displayNames={displayNames}
              tableCategory="Total Responsibility"
            />
          </div>
        </div>
      </div>
    </GenericPageContainer>
  )
}))
