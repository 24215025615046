import { view } from "@risingstack/react-easy-state";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "./propertiesNeverUpdated.scss"
import SmartTable from "../../../GenericPageElements/SmartTable";
import endpoints from "../../../helpers/endpoints";

export default view(withRouter(function PropertiesNeverUpdated() {

  const columns = [
    {
      accessor: 'landlord_name',
      headerDisplay: 'Landlord Name',
      headerAlign: 'left',
      cellAlign: 'left',
      sortable: false,
      columnWidth: 2,
    },
    {
      accessor: 'properties_never_updated',
      headerDisplay: 'Properties Never Updated',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: row => row.landlord?.total_no_of_active_properties,
      id: 'distinct_landlord_properties.total_no_of_active_properties',
      headerDisplay: 'Active',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: row => row.landlord?.total_number_of_properties,
      id: 'distinct_landlord_properties.total_number_of_properties',
      headerDisplay: 'Total Properties',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
  ]

  const defaultSort = {
    id: 'properties_never_updated',
    desc: true,
  }

  return (
    <GenericPageContainer
      containerWidth="92vw"
      title="Properties Never Updated"
      titleIconClass={"fak fa-id-house-icon fa-2x colour-primary"}
    >
      <SmartTable 
        columns={columns} 
        dataEndpoint={endpoints.PROPERTIES_NEVER_UPDATED_SUMMARY}
        exportEndpoint={endpoints.PROPERTIES_NEVER_UPDATED_DOWNLOAD}
        defaultSort={defaultSort}
      />
    </GenericPageContainer>
  )
}))
