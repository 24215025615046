import { view } from "@risingstack/react-easy-state";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "./propertiesPerLandlord.scss"
import SmartTable from "../../../GenericPageElements/SmartTable";
import endpoints from "../../../helpers/endpoints";

export default view(withRouter(function PropertiesPerLandlord() {
  const columns = [
    {
      accessor: 'landlord_name',
      headerDisplay: 'Landlord Name',
      headerAlign: 'left',
      cellAlign: 'left',
      sortable: false,
      columnWidth: 2,
    },
    {
      accessor: 'date_updated',
      headerDisplay: 'Last Update Date',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'total_number_of_properties',
      headerDisplay: 'Total Properties',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'total_no_of_active_properties',
      headerDisplay: 'Active',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'total_no_of_archived_properties',
      headerDisplay: 'Archived',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'total_number_of_draft_properties',
      headerDisplay: 'Draft',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'number_of_distinct_properties',
      headerDisplay: 'Total Distinct Properties',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'no_of_distinct_active_properties',
      headerDisplay: 'Distinct Active',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'no_of_distinct_archived_properties',
      headerDisplay: 'Distinct Archived',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
    {
      accessor: 'no_of_distinct_draft_properties',
      headerDisplay: 'Distinct Draft',
      headerAlign: 'right',
      cellAlign: 'right',
      sortable: true,
    },
  ]

  const defaultSort = {
    id: 'total_number_of_properties',
    desc: true,
  }

  return (
    <GenericPageContainer
      containerWidth="92vw"
      title="Properties Per Landlord"
      titleIconClass={"fak fa-id-house-icon fa-2x colour-primary"}
    >
      <SmartTable
        columns={columns}
        dataEndpoint={endpoints.PROPERTIES_PER_LANDLORD_SUMMARY}
        exportEndpoint={endpoints.PROPERTIES_PER_LANDLORD_DOWNLOAD}
        filenamePrefix='properties_per_landlord'
        defaultSort={defaultSort}
      />
    </GenericPageContainer>
  )
}))
